<template>
  <b-overlay :show="loading">
    <div class="home-container">
      <pagination :implementSearch="false" :meta="articlesMeta" @refresh="onRefresh" @change-page="onChangePage">
        <b-button slot="action" @click="onClickAction" class="btn btn-label-success btn-sm border-0">
          {{ $t("COMMON.CREATE") }}
        </b-button>
      </pagination>
      <b-row>
        <b-col sm="12" md="12" v-for="article in articles" :key="article.id">
          <template v-if="article.type === 'raise'">
            <raise-item :article="article" @change-status="onChangeSubjectStatus" />
          </template>
          <template v-if="article.type === 'project'">
            <project-item :article="article" @change-status="onChangeSubjectStatus" />
          </template>
        </b-col>
      </b-row>
      <subject-status-modal v-model="subjectStatusModalState" :article="article" :name="`subject_option_${article.id}`" @success="onChangeStatusSuccess" @fail="onChangeStatusFail" />
      <subject-create-type-modal size="md" v-model="subjectCreateTypeModalState" />
    </div>
  </b-overlay>
</template>

<script>
  import BreadCrumbs from "@c@/breadcrumbs.service";
  import Error from "@v@/partials/widgets/Error";
  import Portlet from "@v@/partials/content/Portlet";
  import RaiseItem from "@v@/components/items/article/RaiseItem";
  import ProjectItem from "@v@/components/items/article/ProjectItem";
  import pagination from "@v@/components/paginate";
  import {mapGetters} from 'vuex';
  import msgBoxConfirmMixin from "@m@/common/msgBoxConfirmMixin";
  import commonMethodMixin from "@m@/common/common.method.mixin";
  import articleMixin from "@m@/article.mixin";
  import SubjectStatusModal from "@v@/components/modals/subject.status.modal";
  import SubjectCreateTypeModal from "@v@/components/modals/subject.create.type.modal"
  export default {
    name: "index",
    components: { Error, Portlet, pagination, RaiseItem, ProjectItem, SubjectStatusModal, SubjectCreateTypeModal },
    mixins: [articleMixin, msgBoxConfirmMixin, commonMethodMixin],
    props: {},
    created() {
      this.autoLoadHomeData();
      this.getArticleCoreData();
    },
    mounted() {
      BreadCrumbs.setBreadCrumb([]);
      BreadCrumbs.addBreadCrumb({title: this.$t("PAGES.ARTICLE.HOME.BREAD_CRUMBS_TITLES.FIRST_TITLE"), route: {name: 'article.home'}});
      BreadCrumbs.addBreadCrumb({title: this.$t("PAGES.ARTICLE.HOME.BREAD_CRUMBS_TITLES.CURRENT_TITLE")});
    },
    watch: {},
    computed: {
      ...mapGetters([
         'articles', 'articlesMeta', 'articlesPage', 'currentAdmin'
      ]),
      lang(){
        return this.$i18n.locale || 'ZH-UG';
      },
      raiseStatusValue(){
        const { raise } = this.article || {};
        if( raise && raise.hasOwnProperty('status') ){
          const { status } = raise;
          return  status;
        }else {
          return '1'
        }
      },
      superAdmin(){
        const { super: superAdmin } = this.currentAdmin;
        return superAdmin || false
      },
      subAccount(){
        const { sub_account: subAccount } = this.currentAdmin;
        return subAccount || false
      },
    },
    data(){
      return {
        loading: false,
        page: 1,
        subjectStatusModalState: false,
        subjectCreateTypeModalState: false,
        article: {},
      }
    },
    methods: {
      autoLoadHomeData(rootPage = null){
        this.loading = true;
        let {articlesPage} = this;
        if( !rootPage ){
          this.page = articlesPage
        }else if (rootPage) {
          this.page = rootPage
        }
        this.getArticles(this.page)
          .catch(err=>{
            console.info('getArticle.err: ', err);
          }).finally(()=>{
            this.loading = false;
          })
      },
      onChangePage(e){
        this.autoLoadHomeData(e.page);
      },
      onRefresh(){
        this.autoLoadHomeData(1);
      },
      onChangeSubjectStatus(article){
        this.article = article;
        this.$nextTick(()=>{
          this.subjectStatusModalState = true;
        });
      },
      onChangeStatusSuccess(){
        this.$nextTick(()=>{
          this.subjectStatusModalState = false;
        });
      },
      onChangeStatusFail(e){},
      onClickAction(){
        const { superAdmin, subAccount } = this;
        if (superAdmin || !subAccount ){
          this.subjectCreateTypeModalState = true
        }else {
          this.$router.push({ name: 'article.store' });
        }
      },
    }
  }
</script>

<style lang="scss" scoped>
  .home-container {
    .btn-group, .btn-group-vertical {
      display: flex !important;
    }
  }

</style>
